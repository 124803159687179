import { Filter, TextInput, NumberInput, DateInput } from 'react-admin';

const toLowerCaseFormatterFn = (value) => (value ? value.toLowerCase() : value);

export const CustomerTransactionsFilter = (props) => (
    <Filter {...props}>
        <NumberInput variant='outlined' source='amountWithTolerance' alwaysOn label='Amount' />
        <TextInput variant='outlined' source='label' alwaysOn />
        <DateInput variant='outlined' source='transactionDate' alwaysOn options={{ format: 'DD/MM/YYYY' }} />
        <TextInput variant='outlined' source='id' label='Transaction Id' />
        <TextInput
            variant='outlined'
            source='storeId'
            inputProps={{ autoCapitalize: 'none' }}
            format={toLowerCaseFormatterFn}
        />
    </Filter>
);
