import { Box, Typography } from '@material-ui/core';
import T from 'prop-types';
import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import { ApiBackOffice } from '../../utils/API';
import { ROUTES } from '../../utils/constants';

export const CustomerPaymentMethod = (props) => {
    const { customerId } = props;

    const { data: bankConnectionsData, isLoading: bankConnectionsLoading } = useQuery(
        'getCustomerBankConnections',
        async () => {
            const response = await ApiBackOffice.get(ROUTES.CUSTOMER_BANK_CONNECTIONS(customerId));
            return response.data;
        }
    );

    const bankConnections = useMemo(
        () => (bankConnectionsData ? bankConnectionsData.bankConnection : []),
        [bankConnectionsData]
    );

    return (
        <Box flex={1} flexDirection='column'>
            {bankConnectionsLoading
                ? 'Loading...'
                : !bankConnections.length
                ? 'Scan user'
                : bankConnections.map(({ id, name, error }) => (
                      <Typography key={id} variant='subtitle1' style={{ color: error ? '#DC143C' : '#60C000' }}>
                          {name}
                      </Typography>
                  ))}
        </Box>
    );
};

CustomerPaymentMethod.propTypes = {
    customerId: T.string.isRequired,
};
