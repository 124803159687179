import { Box, Typography } from '@material-ui/core';
import T from 'prop-types';
import React, { useEffect, useState, useCallback } from 'react';
import { TextInput } from 'react-admin';
import { formatDate } from '../../../../../../utils';
import { CustomerPaymentMethod } from '../../../../../common';
import { API } from '../../../../../../utils/API';
import { ROUTES } from '../../../../../../utils/constants';

export const CustomerDetails = (props) => {
    const { customerId, registrationDate, shoppingHubId, setError } = props;

    const [isLoading, setIsLoading] = useState(true);
    const [shoppingHubData, setShoppingHubData] = useState({})

    const commonBoxProps = {
        flex: 1,
        flexDirection: 'column',
        mr: '1em',
        p: '1em',
        border: 1,
        borderRadius: 10,
        borderColor: '#D1D3D4',
    };

    const getShoppingHubInfo = useCallback(async () => {
        try {
            const response = await API.get(ROUTES.SHOPPING_HUB_DETAILS(shoppingHubId));
            setShoppingHubData(response.data);
            setIsLoading(false)
        } catch (error) {
            setError(error.message)
            setIsLoading(false)
        }
    }, [shoppingHubId])

    useEffect(() => {
        getShoppingHubInfo()
    }, [shoppingHubId])

    return (
        <Box pb='1em' display='flex'>
            <Box {...commonBoxProps}>
                <Typography variant='h6'>Customer ID</Typography>

                <TextInput
                    disabled
                    source='customerId'
                    fullWidth
                    label=''
                    variant='standard'
                    inputProps={{
                        underline: {
                            '&&&:before': {
                                borderBottom: 'none',
                            },
                            '&&:after': {
                                borderBottom: 'none',
                            },
                        },
                    }}
                />
            </Box>
            <Box {...commonBoxProps}>
                <Typography variant='h6'>Hub Name</Typography>
                <Typography variant='subtitle1'>{isLoading ? 'Loading...' : shoppingHubData?.name}</Typography>

                <Typography variant='h6'>Registration date</Typography>
                <Typography variant='p'>{formatDate(registrationDate, '.')}</Typography>
            </Box>
            <Box {...commonBoxProps} mr={0}>
                <Typography variant='h6'>Payment Method</Typography>
                <CustomerPaymentMethod customerId={customerId} />
            </Box>
        </Box>
    );
};

CustomerDetails.propTypes = {
    shoppingHubId: T.string.isRequired,
    customerId: T.string.isRequired,
    registrationDate: T.string.isRequired,
    setError: T.func.isRequired,
};
