import ChevronLeft from '@material-ui/icons/ChevronLeft';
import * as React from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    DateInput,
    required,
    Toolbar,
    SaveButton,
    TopToolbar,
    ListButton,
} from 'react-admin';
import { ROUTES } from '../../../utils/constants';

const TransactionCreateToolbar = (props) => (
    <Toolbar {...props}>
        <SaveButton label='create Transaction' />
    </Toolbar>
);

const ClaimEditActions = (props) => {
    const { claimId } = props.record;

    return (
        <TopToolbar style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <ListButton basePath={ROUTES.CLAIM_DETAILS(claimId)} label='Back To Claim' icon={<ChevronLeft />} />
        </TopToolbar>
    );
};

const redirect = (basePath, id, data) => ROUTES.CLAIM_DETAILS(data.claimId);

const TransactionCreate = (props) => (
    <Create {...props} actions={<ClaimEditActions record={props.location.state.record} />}>
        <SimpleForm redirect={redirect} toolbar={<TransactionCreateToolbar />}>
            <TextInput source='customerId' validate={[required()]} />
            <TextInput source='amount' validate={[required()]} />
            <TextInput source='storeId' validate={[required()]} />
            <TextInput disabled source='currency' validate={[required()]} />
            <DateInput source='transactionDate' defaultValue={new Date()} />
            <DateInput disabled source='scrapingDate' defaultValue={new Date()} />
            <TextInput disabled source='label' defaultValue='MAN-Transaction' />
            <TextInput disabled source='provider' defaultValue='MAN' />
            <DateInput disabled source='createdAt' defaultValue={new Date()} />
            <DateInput disabled source='updatedAt' defaultValue={new Date()} />
        </SimpleForm>
    </Create>
);
export default TransactionCreate;
