import * as React from 'react';
import { URL } from '../../../utils/constants';

export const DisplayProgramLogo = (props) => {
    const { record } = props;
    const url = URL.LOGO_URL(record.shortcut);
    return (
        <div>
            <img src={url} alt='' style={{ maxWidth: '100px', maxHeight: '100px' }} />
        </div>
    );
};
