import { fetchUtils } from 'ra-core';
import simpleRestProvider from 'ra-data-simple-rest';
import { ROUTES } from '../utils/constants';

const httpClient = (url, options = {}) => {
    // eslint-disable-next-line
    options.headers = new Headers({
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
    });
    return fetchUtils.fetchJson(url, options);
};

const provider = simpleRestProvider(`${process.env.REACT_APP_TC_HOST}${ROUTES.BO_REST_PROVIDER}`, httpClient);

// To disable pagination -> params.pagination.perPage === false
// It will disable pagination for current logic

export const restProvider = {
    ...provider,
    getList: (resource, params) => {
        if (resource === 'transaction') {
            const paramsUpdated = JSON.parse(JSON.stringify(params));

            if (params.filter.amount !== undefined) {
                const { amount } = paramsUpdated.filter;
                paramsUpdated.filter.amount = [amount, amount * -1];
            } else if (params.filter.amountWithTolerance !== undefined) {
                const amount = -Math.abs(parseInt(paramsUpdated.filter.amountWithTolerance, 10));
                delete paramsUpdated.filter.amountWithTolerance;

                paramsUpdated.filter.amount = {
                    $between: [amount - 1, amount + 1],
                };
            }

            if (params.filter.label !== undefined) {
                const { label } = paramsUpdated.filter;
                paramsUpdated.filter.label = { $iLike: `%${label}%` };
            }

            return provider.getList(resource, paramsUpdated);
        }

        if (resource === 'store' && params.filter.q) {
            const paramsUpdated = JSON.parse(JSON.stringify(params));
            const { q } = paramsUpdated.filter;
            paramsUpdated.filter.name = { $iLike: `%${q}%` };
            delete paramsUpdated.filter.q;

            return provider.getList(resource, paramsUpdated);
        }

        return provider.getList(resource, params);
    },
};
