import { useSelectedColumns } from '@react-admin/ra-preferences';
import { List, Datagrid, ReferenceField, TextField, EditButton, DateField } from 'react-admin';
import COLUMNS from '../../../utils/enums';
import { CustomPagination, ListActions } from '../../common';
import CustomerFilter from './CustomerFilter';

const customerListColumns = {
    id: <TextField source='id' label='Customer ID' />,
    email: <TextField source='email' label='Email' />,
    phone: <TextField source='phone' label='Phone' />,
    registration: <DateField source='registrationDate' label='Registration Date' />,
    shoppingHub: (
        <ReferenceField
            source='shoppingHubId'
            reference='shoppingHub'
            label='Shopping Hub'
            link={(record, reference) => `${reference}/${record.id}`}
        >
            <TextField source='name' />
        </ReferenceField>
    ),
    edit: <EditButton />,
};

const CustomerList = (props) => {
    const displayedColumns = useSelectedColumns({
        preferences: COLUMNS.customer,
        columns: customerListColumns,
        omit: ['nb_views'],
    });
    return (
        <List
            {...props}
            perPage={16}
            pagination={<CustomPagination />}
            filters={<CustomerFilter />}
            actions={<ListActions columns={customerListColumns} preference={COLUMNS.customer} />}
            bulkActionButtons={false}
        >
            <Datagrid rowClick='show'>{displayedColumns}</Datagrid>
        </List>
    );
};

export default CustomerList;
