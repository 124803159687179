import * as React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DeleteButton,
    Filter,
    TextInput,
    ReferenceField,
    DateField,
    EditButton,
} from 'react-admin';
import noImageAvailable from '../../assets/img/noImageAvailable.png';
import { CustomPagination } from '../common';
import { ImageField } from '../common/fields/ImageField';
import StatusPendingOrOkField from '../common/fields/StatusPendingOrOkField';

const StoreReferentialFilter = (props) => (
    <Filter {...props}>
        <TextInput label='shoppingHubId' source='shoppingHubId' alwaysOn variant='outlined' />
        <TextInput label='name' source='name' variant='outlined' />
    </Filter>
);

// TODO Roman reuse custom list component after it will be merged into develop

export const StoreReferentialList = (props) => (
    <List
        {...props}
        perPage={16}
        pagination={<CustomPagination />}
        filters={<StoreReferentialFilter />}
        sort={{ field: 'createdAt', order: 'DESC' }}
        bulkActionButtons={false}
    >
        <Datagrid rowClick='show'>
            <ReferenceField source='storeId' reference='store' label='Logo'>
                <ImageField source='logoUrl' imageAltLabel='store-logo' defaultImageSrc={noImageAvailable} />
            </ReferenceField>
            <TextField source='name' />
            <TextField source='alias' />
            <ReferenceField source='shoppingHubId' reference='shoppingHub'>
                <TextField source='name' />
            </ReferenceField>
            <TextField source='externalId' label='ExternalId' />
            <TextField source='locality' />
            <DateField source='synchronizationDate' label='Synchronization Date' />,
            <StatusPendingOrOkField source='storeId' label='Synchronized' />
            <EditButton />
            <DeleteButton />
        </Datagrid>
    </List>
);

export default StoreReferentialList;
