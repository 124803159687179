import T from 'prop-types';
import React from 'react';
import { DateInput, TextInput, ReferenceInput, AutocompleteInput } from 'react-admin';

const EditMode = ({ data, readOnly, attachmentUrl }) => (
    <>
        <TextInput variant='outlined' source='transactionId' label='Transaction ID' disabled={readOnly} />
        <TextInput variant='outlined' source='amount' label='Amount' disabled={readOnly} />
        <DateInput variant='outlined' disabled source='transactionDate' />
        <TextInput variant='outlined' disabled source='additionalFeedback' multiline label='Customer Comment' />
        {attachmentUrl && (
            <a href={attachmentUrl} target="_blank" rel="noreferrer">
                Attachment
            </a>
        )}
        <ReferenceInput source='storeId' reference='store'>
            <AutocompleteInput optionText='name' variant='outlined' disabled fullWidth />
        </ReferenceInput>
        <TextInput
            disabled
            variant='outlined'
            source='shoppingHubId'
            label='HUB ID'
            defaultValue={data?.shoppingHubId}
        />
    </>
);

EditMode.propTypes = {
    data: T.shape({
        shoppingHubId: T.string,
    }).isRequired,
    readOnly: T.bool.isRequired,
};

export default EditMode;
