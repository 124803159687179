import T from 'prop-types';
import React, { useCallback } from 'react';
import { useRecordContext } from 'react-admin';

import * as styles from './ImageField.styles';

export const ImageField = (props) => {
    const { source, defaultImageSrc, imageAltLabel } = props;
    const context = useRecordContext(props);
    const src = context[source] || (context?.record && context?.record[source]) || defaultImageSrc;

    const onImgError = useCallback(
        (e) => {
            e.target.src = defaultImageSrc;
        },
        [defaultImageSrc]
    );

    return (
        <styles.ImageFieldWrapper>
            <img alt={imageAltLabel} src={src} onError={onImgError} />
        </styles.ImageFieldWrapper>
    );
};

ImageField.defaultProps = {
    imageAltLabel: 'image',
};

ImageField.propTypes = {
    imageAltLabel: T.string,
    source: T.string.isRequired,
    defaultImageSrc: T.string.isRequired,
};
