import { useSelectedColumns } from '@react-admin/ra-preferences';
import { List, Datagrid, ReferenceField, TextField, DateField } from 'react-admin';
import COLUMNS from '../../../../utils/enums';
import { CustomPagination, ListActions } from '../../../common';
import RewardFilters from './RewardFilters';

const rewardListColumns = {
    id: <TextField source='id' label='Reward ID' />,
    customerId: <TextField source='customerId' label='Customer ID' />,
    rewardDate: <DateField source='createdAt' label='Date' />,
    amount: <TextField source='rewardValue' label='Amount' />,
    state: <TextField source='rewardState' label='State' />,
    providerStatus: <TextField source='mgPayoutStatus' label='Prov Status' />,
    rewardStatus: <TextField source='cashbackFinalStatus' label='Status' />,
    challengeName: (
        <ReferenceField source='challengeId' reference='challenge' label='Challenge Name'>
            <TextField source='name' />
        </ReferenceField>
    ),
};
const RewardList = (props) => {
    // customProps are retrieved from the parent component to override parent props with new related ressource and basePath
    // like below const customProps = { ...props, resource: 'transaction', basePath: '/transaction' };
    // add childComponent to make sure to use it inside a component
    const { filter = {}, customProps, childComponent } = props;
    const displayedColumns = useSelectedColumns({
        preferences: COLUMNS.reward,
        columns: rewardListColumns,
        omit: ['nb_views'],
    });
    return (
        <List
            key='reward-list'
            {...props}
            resource={childComponent ? customProps.resource : props.resource}
            basePath={childComponent ? customProps.basePath : props.basePath}
            hasCreate={false}
            perPage={16}
            sort={{ field: 'createdAt', order: 'DESC' }}
            pagination={<CustomPagination />}
            filters={<RewardFilters />}
            filter={filter}
            actions={<ListActions columns={rewardListColumns} preference={COLUMNS.reward} />}
            bulkActionButtons={false}
        >
            <Datagrid size='medium'>{displayedColumns}</Datagrid>
        </List>
    );
};

export default RewardList;
