import T from 'prop-types';
import React from 'react';
import { customerShowStyle } from '../../../../../utils/style';
import { BoxElementDisplay, TypoElement, CustomerPaymentMethod } from '../../../../common';

export const CustomerElementPaymentMethod = ({ customerId }) => (
    <BoxElementDisplay>
        <TypoElement content='Payment Method' {...customerShowStyle[0]} />
        <CustomerPaymentMethod customerId={customerId} />
    </BoxElementDisplay>
);

CustomerElementPaymentMethod.propTypes = {
    customerId: T.string.isRequired,
};
