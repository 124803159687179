export const ROUTES = {
    BO_LOGIN: '/backoffice/auth/login',
    BO_PASSWORD_RESET: '/backoffice/auth/passwordReset',
    BO_ACCOUNT_UPDATE: '/backoffice/auth/accountUpdate',
    BO_REST_PROVIDER: '/backoffice/v1/model',
    BACK_OFFICE: '/backoffice',
    CREATE_TRANSACTION: '/transaction/create',
    TRANSACTION_LIST: '/transaction',
    REJECTION_REASON_LIST: '/RejectionReason',
    REWARD_LIST: '/reward',
    STORE: (storeId) => `/store/${storeId}`,
    CUSTOMER_BANK_CONNECTIONS: (customerId) => `/customers/${customerId}/bankConnections`,
    CUSTOMER_DETAILS: (customerId) => `/customer/${customerId}`,
    CLAIM_DETAILS: (claimId) => `/claim/${claimId}`,
    SHOPPING_HUB_DETAILS: (shoppingHubId) => `/ShoppingHub/${shoppingHubId}`,
};

export const URL = {
    LOGO_URL: (shorcut) => `https://tc-front.transactionconnect.com/resources/${shorcut}/website_logo.png`,
};
