import axios from 'axios';
import { ROUTES } from '../constants';

export const API = axios.create({
    baseURL: `${process.env.REACT_APP_TC_HOST}${ROUTES.BO_REST_PROVIDER}`,
    headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
    },
});

API.attachToken = (token) => (API.defaults.headers.Authorization = `Bearer ${token}`);
API.detachToken = () => delete API.defaults.headers.Authorization;
