/* eslint-disable react/forbid-prop-types,react/require-default-props */
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import PropTypes from 'prop-types';
import * as React from 'react';

const StatusPendingOrOkField = (props) => {
    const { source, record } = props;
    return <span>{record[source] ? <CheckCircleIcon /> : <AutorenewIcon color='green' />}</span>;
};

StatusPendingOrOkField.propTypes = {
    source: PropTypes.string.isRequired,
    record: PropTypes.object,
};

export default StatusPendingOrOkField;
