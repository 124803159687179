import { startSession, stopSession } from '../utils/API';
import { ROUTES } from '../utils/constants';
import LogRocket from 'logrocket';
import jwtDecode from 'jwt-decode';

export default {
    // called when the user attempts to log in
    login: ({ email, password }) => {
        const request = new Request(`${process.env.REACT_APP_TC_HOST}${ROUTES.BO_LOGIN}`, {
            method: 'POST',
            body: JSON.stringify({ email, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    return response.json().then((err) => {
                        throw err;
                    });
                }
                return response.json();
            })
            .then((response) => {
                const { accessToken } = response;
                LogRocket.identify(response.backUserId, {email: response.backUserEmail} );
                startSession(accessToken, { id: response.backUserId, email: response.backUserEmail });
            });
    },
    // called when the user wants to reset his password by email
    passwordResetRequest: ({ email, locale = 'en' }) => {
        const request = new Request(`${process.env.REACT_APP_TC_HOST}${ROUTES.BO_PASSWORD_RESET}`, {
            method: 'POST',
            body: JSON.stringify({ email }),
            headers: new Headers({ 'Content-Type': 'application/json', 'Accept-Language': locale }),
        });
        return fetch(request)
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    return response.json().then((err) => {
                        throw err;
                    });
                }
                return response;
            })
    },
    // called when the user wants to reset his password by email
    accountUpdate: ({ token, data }) => {
        const request = new Request(`${process.env.REACT_APP_TC_HOST}${ROUTES.BO_ACCOUNT_UPDATE}`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }),
        });
        return fetch(request)
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    return response.json().then((err) => {
                        throw err;
                    });
                }
                return response;
            })
    },
    // called when the user clicks on the logout button
    logout: () => {
        stopSession();
        return Promise.resolve();
    },
    // called when the API returns an error
    checkError: ({ status }) => {
        if (status === 401 || status === 403) {
            stopSession();
            return Promise.reject();
        }
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
        const token = sessionStorage.getItem('accessToken');
        if(!token || token === 'undefined') {
            return Promise.reject()
        }
        const decodedToken = jwtDecode(token).exp
        const currentTime = new Date().getTime() / 1000;
        if (currentTime > decodedToken.exp) {
            // expired token
            return Promise.reject()
        }
        return Promise.resolve()
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => Promise.resolve(),
};
