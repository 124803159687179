import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import T from 'prop-types';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../../../utils/constants';
import jwtDecode from "jwt-decode";

const CreateTransactionButton = ({ record: { customerId, transactionDate, storeId, amount, id }, currency }) => {
    const token = sessionStorage.getItem('accessToken');
    const backUserId = token ? jwtDecode(token).backUserId : null;

    return (
    <Button
        component={Link}
        variant='outlined'
        color='primary'
        endIcon={<AddIcon />}
        style={{ marginBottom: 16, width: '100%' }}
        to={{
            pathname: ROUTES.CREATE_TRANSACTION,
            state: {
                record: {
                    // values passed to be in default for the new transaction
                    claimId: id,
                    currency,
                    label: 'MAN-TRANSACTION',
                    storeAssociationOrigin: 'MAN',
                    storeAssociationDate: new Date(),
                    storeAssociationAuthor: backUserId,
                    // values passed from claim to the transaction creation
                    customerId,
                    transactionDate,
                    storeId,
                    amount,
                },
            },
        }}
    >
        New transaction
    </Button>
)};

CreateTransactionButton.propTypes = {
    record: T.shape({
        customerId: T.string.isRequired,
        transactionDate: T.string.isRequired,
        storeId: T.string.isRequired,
        amount: T.number.isRequired,
        id: T.string.isRequired,
    }).isRequired,
    currency: T.string.isRequired,
};

export default CreateTransactionButton;
