import TextField from '@material-ui/core/TextField';
import React from 'react';
import { useInput } from 'react-admin';

export default function LoginTextField(props) {
    const {
        input: { name, onChange, type },
        meta: { touched, error },
        isRequired,
    } = useInput(props);

    return (
        <div className='form__fields'>
            <TextField
                name={name}
                type={type}
                label={props.label}
                onChange={onChange}
                error={!!(touched && error)}
                helperText={touched && error}
                required={isRequired}
                variant='outlined'
            />
        </div>
    );
}
