import T from 'prop-types';
import React, { useMemo } from 'react';
import { Show, SimpleShowLayout, TextField, DateField } from 'react-admin';
import { useQuery } from 'react-query';

import { formatDate } from '../../../../utils';
import { API } from '../../../../utils/API';
import { ROUTES } from '../../../../utils/constants';

import { ShowActions } from '../../../common';
import {
    CustomerElementTransactionList,
    CustomerElementPaymentMethod,
    CustomerElementRewardList,
} from './CustomerShowChildComponents';
import { CustomerElement } from './CustomerShowSharedComponents';

const PostTitle = ({ record }) => <span>Post {record ? `"${record.lastName}"` : ''}</span>;

const CustomerShow = (props) => {
    const customerId = props.id;
    const transactionListCustomProps = { ...props, resource: 'transaction', basePath: ROUTES.TRANSACTION_LIST };
    const rewardListCustomProps = { ...props, resource: 'reward', basePath: ROUTES.REWARD_LIST };

    const { data: customerRecord } = useQuery('getCustomerInfos', async () => {
        const response = await API.get(ROUTES.CUSTOMER_DETAILS(customerId));
        return response.data;
    });

    const transactionsEligibleFromDate = useMemo(() => {
        const date = customerRecord?.transactionsEligibleFromDate;

        return date ? formatDate(date) : 'N/A';
    }, [customerRecord]);

    return (
        <Show {...props} title={<PostTitle />} actions={<ShowActions />}>
            <SimpleShowLayout>
                <TextField source='id' />
                <CustomerElement title='External ID' element={customerRecord?.externalId || 'N/A'} />
                <TextField source='phone' />
                <TextField source='email' />
                <DateField source='registrationDate' label='Registration Date' />
                <CustomerElementPaymentMethod customerId={customerId} />
                <CustomerElement title='Transaction Eligible From Date' element={transactionsEligibleFromDate} />
                <CustomerElementTransactionList
                    customerId={customerId}
                    transactionListCustomProps={transactionListCustomProps}
                />
                <CustomerElementRewardList customerId={customerId} rewardListCustomProps={rewardListCustomProps} />
            </SimpleShowLayout>
        </Show>
    );
};

export default CustomerShow;

CustomerShow.propTypes = {
    id: T.string.isRequired,
};
