import Avatar from '@material-ui/core/Avatar';
import gravatarUrl from '../utils/image';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { AppBar, UserMenu } from 'react-admin';
import MainLogo from '../assets/img/Spaycial_white.svg';
import { LanguageSwitcher } from '@react-admin/ra-preferences';

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    avatar: {
        height: 30,
        width: 30,
    },
    spacer: {
        flex: 1,
    },
});

const MyCustomIcon = () => {
    const classes = useStyles();
    const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
    const avatarUrl = userInfo && userInfo.email ? gravatarUrl(userInfo.email) : '';
    return <Avatar className={classes.avatar} src={avatarUrl} />;
};
const MyUserMenu = (props) => <UserMenu {...props} icon={<MyCustomIcon />} />;
const CustomAppBar = (props) => {
    const classes = useStyles();
    return (
        <AppBar {...props} userMenu={<MyUserMenu />} color='primary'>
            <Typography variant='h6' color='inherit' className={classes.title} id='react-admin-title' />
            <img alt='Main logo' src={MainLogo} style={{ height: 50 }} />
            <span className={classes.spacer} />
            <LanguageSwitcher
                languages={[
                    { locale: 'en', name: 'English' },
                    { locale: 'fr', name: 'Français' },
                ]}
            />
        </AppBar>
    );
};

export default CustomAppBar;
