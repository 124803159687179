import jwt_decode from 'jwt-decode';

export const FUNCTIONALITIES = {
    SUPPORT_LVL_1: 'SUPPORT_LVL_1',
    TRANSACTION_PINPOINT: 'TRANSACTION_PINPOINT',
    HUB_SETUP_INIT: 'HUB_SETUP_INIT',
    STORE_RETAILER_MANAGEMENT: 'STORE_RETAILER_MANAGEMENT',
    STATISTIC_BOARD: 'STATISTIC_BOARD',
    ADMIN: 'ADMIN',
}

/*
* functionalityKey - permission key to check
* requiredValues - string or array of strings. Permission levels to check
*
* If on of required permission values to check match function return true
* */

export const checkUserPermission = (functionalityKey, requiredValues = []) => {
    const valuesToCheck = typeof requiredValues === 'string' ? [requiredValues] : requiredValues
    const token = sessionStorage.getItem('accessToken');
    const { functionalities } = jwt_decode(token);
    const functionality = functionalities.find((functionnality) => functionalityKey in functionnality)
    const functionalityValue = functionality[functionalityKey];

    return valuesToCheck.includes(functionalityValue)
}
