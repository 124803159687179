import React from 'react';
import { Show, SimpleShowLayout, TextField, ReferenceField } from 'react-admin';
import { ShowActions } from '../common';

export const StoreReferentialShow = (props) => (
    <Show {...props} actions={<ShowActions />}>
        <SimpleShowLayout>
            <TextField source='name' variant='outlined' />
            <TextField source='alias' variant='outlined' />
            <ReferenceField source='shoppingHubId' reference='shoppingHub' label='ShoppingHub' variant='outlined'>
                <TextField source='name' variant='outlined' />
            </ReferenceField>
            <TextField source='externalId' label='external ID' variant='outlined' />
            <TextField source='locality' label='Locality' variant='outlined' />
        </SimpleShowLayout>
    </Show>
);
