export const customerShowStyle = [
    {
        variant: 'caption',
        color: 'textSecondary',
    },
    {
        variant: 'body2',
        color: 'textPrimary',
        style: { padding: '8px 0 4px' },
    },
];
