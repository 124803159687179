import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Show, SimpleShowLayout, TextField, ReferenceField } from 'react-admin';

import { ShowActions } from '../../common';

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});
const PostTitle = ({ record }) => <span>Claim number {record ? `"${record.id}"` : ''}</span>;

const Aside = ({ record }) => {
    const classes = useStyles();
    return (
        <div style={{ width: 200, margin: '1em' }}>
            <div style={{ borderWidth: 15, borderColor: 'purple', height: 150, width: 200, marginBottom: 16 }}>
                customer ID : {record ? record.customerId : 'yoyoy'}
            </div>
            <Card variant='outlined'>
                <CardContent>
                    {/* <Typography variant='h5' component='h2'>
                        be{bull}nev{bull}o{bull}lent
                    </Typography> */}
                    <div style={{ display: 'flex' }}>
                        <Typography className={classes.pos} color='textSecondary'>
                            claim ID :
                        </Typography>
                        <Typography variant='subtitle1' align='center' component='p'>
                            {record ? record.id : 'n/D'}
                        </Typography>
                    </div>
                    <Typography className={classes.pos} color='textSecondary'>
                        store ID :
                    </Typography>
                    <Typography variant='body2' component='p'>
                        {record ? record.storeId : 'n/D'}
                    </Typography>
                    <Typography className={classes.pos} color='textSecondary'>
                        amount :
                    </Typography>
                    <Typography variant='body2' component='p'>
                        {record ? record.amount : 'n/D'}
                    </Typography>
                    <Typography className={classes.pos} color='textSecondary'>
                        transaction Date :
                    </Typography>
                    <Typography variant='body2' component='p'>
                        {record ? record.transactionDate : 'n/D'}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button size='small'>Learn More</Button>
                </CardActions>
            </Card>
        </div>
    );
};
const ClaimShow = (props) => (
    <Show {...props} title={<PostTitle />} actions={<ShowActions />} aside={<Aside />}>
        <SimpleShowLayout>
            <TextField source='id' />

            <TextField source='customerId' />

            <ReferenceField source='customerId' reference='customer' label='referalCode'>
                <TextField source='referralCode' />
            </ReferenceField>

            <TextField source='storeId' />
            <ReferenceField source='storeId' reference='store' label='store name'>
                <TextField source='name' />
            </ReferenceField>

            <TextField source='amount' />
            <TextField source='transactionDate' />
            <TextField source='transactionDate' />
            <TextField source='claimStatus' />
        </SimpleShowLayout>
    </Show>
);

export default ClaimShow;
