import { Filter, TextInput } from 'react-admin';

const CustomerFilter = (props) => (
    <Filter {...props}>
        <TextInput alwaysOn source='id' variant='outlined' />
        <TextInput alwaysOn source='email' variant='outlined' />
        <TextInput alwaysOn source='phone' variant='outlined' />
        <TextInput alwaysOn source='externalId' variant='outlined' label='External ID' />
    </Filter>
);

export default CustomerFilter;
