import Button from '@material-ui/core/Button';
import React, { useState } from 'react';
import { useLogin, required, minLength, Loading, useNotify, Notification, useTranslate } from 'react-admin';
import { Form } from 'react-final-form';
import LoginTextField from './LoginTextField';
import * as styles from './styles/Login.styles';
import authProvider from "../../../providers/authProvider";
import { useLocation, useHistory } from 'react-router-dom'

const RequestPassword = () => {
    const [loading, setLoading] = useState(false);
    const notify = useNotify();
    const t = useTranslate();
    const { search } = useLocation()
    const urlParams = new URLSearchParams(search)
    const queryString = Object.fromEntries(urlParams)
    const history = useHistory()

    const onSubmit = (data) => {
        const { password, passwordConfirmation} = data
        if(passwordConfirmation !== password) {
            notify(t('resources.resetPassword.notification.passwordNoMatch'), 'error');
        } else {
            setLoading(true);
            authProvider.accountUpdate({ token: queryString.t, data: {password: data.password} })
                .then(() => {
                    setLoading(false)
                    notify(t('resources.resetPassword.notification.passwordUpdated'), 'info');
                    history.push('/login')
                })
                .catch((err) => {
                    setLoading(false);
                    notify(err.message);
                });
        }
    };

    return (
        <styles.loginWrapper>
            <div className='page__title__container'>
                <h1>{t('resources.login.baseline')}</h1>
            </div>
            <styles.formContainer>
                <div className='form__title'>
                    <h1>{t('resources.resetPassword.title')}</h1>
                    <span>{t('resources.resetPassword.subtitle')}</span>
                </div>
                <Form
                    onSubmit={onSubmit}
                    render={({ handleSubmit, form }) => (
                        <form onSubmit={(data) => handleSubmit(data)} className='form-container'>
                            <LoginTextField
                                source='password'
                                type='password'
                                name='password'
                                label={t('resources.resetPassword.form.password')}
                                validate={[required()]}
                            />
                            <LoginTextField
                                source='passwordConfirmation'
                                type='password'
                                name='passwordConfirmation'
                                label={t('resources.resetPassword.form.passwordConfirmation')}
                                validate={[required()]}
                            />
                            <Button
                                variant='contained'
                                color='primary'
                                disableElevation
                                type='submit'
                                className='form__button'
                                disabled={loading}
                            >
                                {loading && (
                                    <i
                                        className="fa fa-refresh fa-spin"
                                        style={{ marginRight: "5px" }}
                                    />
                                )}
                                {loading && <span>{t('resources.resetPassword.form.submit.loading')}</span>}
                                {!loading && <span>{t('resources.resetPassword.form.submit.default')}</span>}
                            </Button>
                        </form>
                    )}
                />
                <Notification />
            </styles.formContainer>
        </styles.loginWrapper>
    );
};

export default RequestPassword;
