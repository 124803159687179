import axios from 'axios';
import { ROUTES } from '../constants';

export const ApiBackOffice = axios.create({
    baseURL: `${process.env.REACT_APP_TC_HOST}${ROUTES.BACK_OFFICE}`,
    headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
    },
});

ApiBackOffice.attachToken = (token) => (ApiBackOffice.defaults.headers.Authorization = `Bearer ${token}`);
ApiBackOffice.detachToken = () => delete ApiBackOffice.defaults.headers.Authorization;
