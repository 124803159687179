import ChevronLeft from '@material-ui/icons/ChevronLeft';
import React from 'react';
import { Edit, TopToolbar, ListButton } from 'react-admin';

import ClaimEditForm from './ClaimEditForm/ClaimEditForm';

const ClaimEditActions = ({ basePath }) => (
    <TopToolbar style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <ListButton basePath={basePath} label='Claim List' icon={<ChevronLeft />} />
    </TopToolbar>
);

const ClaimEdit = (props) => (
    <Edit title='Edit Claim' actions={<ClaimEditActions />} {...props}>
        <ClaimEditForm />
    </Edit>
);

export default ClaimEdit;
