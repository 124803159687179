import Tooltip from '@material-ui/core/Tooltip';
import * as React from 'react';
import { List, Datagrid, TextField, ReferenceManyField, useTranslate } from 'react-admin';
import { CustomPagination, DisplayProgramLogo, Title } from '../../common';

const DisplayNumberOfReference = (props) => (
    <div style={{ textAlign: 'center' }}>
        <span>{props.total}</span>
    </div>
);

const DisplayWalletBalance = (props) => {
    const {
        record: { balanceCheckout, balanceMangoPay },
    } = props;
    if (!balanceCheckout && !balanceMangoPay) return <span className='wallet_balance'>N/A</span>;
    if (balanceMangoPay && !balanceCheckout)
        return (
            <Tooltip title='Wallet MGP'>
                <span className='wallet_balance'>{balanceMangoPay / 100} €</span>
            </Tooltip>
        );
    if (balanceCheckout && !balanceMangoPay)
        return (
            <Tooltip title='Wallet CKO'>
                <span className='wallet_balance'>{balanceCheckout / 100} €</span>
            </Tooltip>
        );
    return (
        <div className='wallet_balance_container'>
            <Tooltip title='Wallet MGP'>
                <span className='wallet_balance'>{balanceMangoPay / 100} € </span>
            </Tooltip>
            <Tooltip title='Wallet CKO'>
                <span className='wallet_balance'>{balanceCheckout / 100} €</span>
            </Tooltip>
        </div>
    );
};

const DisplayDataRetrievalMethod = (props) => {
    const {
        record: { hasAccountLinking, hasClo, scanEnable },
    } = props;
    return (
        <div className='data_retrieval_method_container'>
            {hasAccountLinking && (
                <div className='data_retrieval_method'>
                    <span className='data_retrieval_method_name'>Account Linking </span>
                </div>
            )}
            {hasClo && (
                <div className='data_retrieval_method'>
                    <span className='data_retrieval_method_name'>CLO </span>
                </div>
            )}
            {scanEnable && (
                <div className='data_retrieval_method'>
                    <span className='data_retrieval_method_name'>Scan </span>
                </div>
            )}
        </div>
    );
};

const ProgramList = (props) => {
    const t = useTranslate();
    return (
        <div>
            <Title title={props.options.label} subtitle={t('resources.shoppingHub.subtitle')} />
            <List
                {...props}
                perPage={16}
                pagination={<CustomPagination />}
                sort={{ field: 'createdAt', order: 'DESC' }}
                bulkActionButtons={false}
            >
                <Datagrid rowClick='show' className='datagrid__header'>
                    <DisplayProgramLogo source='Programmes' />
                    <TextField source='name' textAlign='center' />
                    <ReferenceManyField
                        label='Members'
                        filter={{ registered: true }}
                        reference='customer'
                        target='shoppingHubId'
                    >
                        <DisplayNumberOfReference />
                    </ReferenceManyField>
                    <ReferenceManyField label='Stores' reference='store' sortBy='total' target='shoppingHubId'>
                        <DisplayNumberOfReference />
                    </ReferenceManyField>
                    <ReferenceManyField
                        label='Active challenge'
                        filter={{ startDate: { $lt: new Date() }, endDate: { $gt: new Date() } }}
                        reference='challenge'
                        target='shoppingHubId'
                    >
                        <DisplayNumberOfReference />
                    </ReferenceManyField>
                    <TextField source='scanEnable' textAlign='center' />
                    <ReferenceManyField
                        label='Banks associated'
                        reference='bankShoppingHubAssociation'
                        target='shoppingHubId'
                    >
                        <DisplayNumberOfReference />
                    </ReferenceManyField>
                    <DisplayWalletBalance textAlign='center' data={props} source='Wallet Balance' />
                    <DisplayDataRetrievalMethod textAlign='center' data={props} source='Data Retrieval Method' />
                </Datagrid>
            </List>
        </div>
    );
};

export default ProgramList;
