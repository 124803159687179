import * as React from 'react';
import { Create, SimpleForm, TextInput, DateInput, required } from 'react-admin';

const StoreCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect='show'>
            <TextInput source='name' validate={[required()]} />
            <TextInput source='shoppingHubId' label='shoppingHub ID' validate={[required()]} />
            <DateInput source='createdAt' validate={[required()]} />
        </SimpleForm>
    </Create>
);
export default StoreCreate;
