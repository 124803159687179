import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'
/**
 * Initialize LogRocket, a front-end monitoring tool
 * see https://docs.logrocket.com/reference for more information
 */
export const initLogRocket = () => {
    let appId //= 'rlrfzl/platform-staging-lex6q' // uncomment to activate LogRocket in dev env
    if (process.env.NODE_ENV !== 'development') {
        if (process.env.REACT_APP_TC_HOST === 'https://api-test.transactionconnect.com') {
            appId = 'rlrfzl/platform-staging-lex6q'
        } else {
            appId = 'rlrfzl/platform-production-uyuhg'
        }
    }
    if (appId) {
        LogRocket.init(appId, {
            network: {
                requestSanitizer: (request) => {
                    if (request.headers.Authorization) {
                        request.headers.Authorization = 'hidden'
                    }
                    if (request.url.toLowerCase().indexOf('login') !== -1) {
                        request.body = 'hidden'
                    }
                    return request
                },
            },
        })
        setupLogRocketReact(LogRocket)
    }
}
