import Button from '@material-ui/core/Button';
import React, { useState } from 'react';
import { required, useNotify, Notification, useTranslate, useLocale } from 'react-admin';
import authProvider from '../../../providers/authProvider';
import { Form } from 'react-final-form';
import LoginTextField from './LoginTextField';
import * as styles from './styles/Login.styles';
import { useHistory } from 'react-router-dom'

const RequestPassword = () => {
    const [loading, setLoading] = useState(false);
    const notify = useNotify();
    const t = useTranslate();
    const history = useHistory();
    const locale = useLocale();

    const onSubmit = (data) => {
        setLoading(true);
        authProvider.passwordResetRequest({ email: data.email, locale })
            .then(() => {
                setLoading(false)
                notify(t('resources.requestPassword.notification.resetRequestSent'), 'info');
                history.push('/login')
            })
            .catch((err) => {
                setLoading(false);
                notify(err.message);
            });
    };

    return (
        <styles.loginWrapper>
            <div className='page__title__container'>
                <h1>{t('resources.login.baseline')}</h1>
            </div>
            <styles.formContainer>
                <div className='form__title'>
                    <h1>{t('resources.requestPassword.title')}</h1>
                    <span>{t('resources.requestPassword.subtitle')}</span>
                </div>
                <Form
                    onSubmit={onSubmit}
                    render={({ handleSubmit, form }) => (
                        <form onSubmit={(data) => handleSubmit(data)} className='form-container'>
                            <LoginTextField
                                source='email'
                                type='email'
                                name='email'
                                label={t('resources.requestPassword.form.email')}
                                validate={[required()]}
                            />
                            <Button
                                variant='contained'
                                color='primary'
                                disableElevation
                                type='submit'
                                className='form__button'
                                disabled={loading}
                            >
                                {loading && (
                                    <i
                                        className="fa fa-refresh fa-spin"
                                        style={{ marginRight: "5px" }}
                                    />
                                )}
                                {loading && <span>{t('resources.requestPassword.form.submit.loading')}</span>}
                                {!loading && <span>{t('resources.requestPassword.form.submit.default')}</span>}
                            </Button>
                        </form>
                    )}
                />
                <Notification />
            </styles.formContainer>
        </styles.loginWrapper>
    );
};

export default RequestPassword;
