import frenchMessages from "ra-language-french";
import englishMessages from "ra-language-english";
import polyglotI18nProvider from "ra-i18n-polyglot";

import { resolveBrowserLocale } from 'react-admin';

const domainMessages = {
    en: {
        resources: {
            login: {
                title: 'Hello!',
                subtitle: 'Are you ready to enjoy your session?',
                baseline: 'Let\'s be spaycial today!',
                form: {
                    email: 'e-mail',
                    password: 'password',
                    passwordConfirmation: 'confirmation',
                    submit: {
                        default: 'Log me in',
                        loading: 'Logging...',
                    }
                },
                forgottenPassword: 'Forgot your password?',
                notification: {
                    blockedAccount: 'Your account is blocked, please reset your password',
                    resetRequestSent: 'Your request has been accepted, check your e-mails'
                }
            },
            requestPassword: {
                title: 'Forgot your password?',
                subtitle: 'Please enter your e-mail',
                form: {
                    email: 'e-mail',
                    submit: {
                        default: 'Send a reset password email',
                        loading: 'Sending...',
                    }
                },
                notification: {
                    resetRequestSent: 'Your request has been accepted, check your email inbox'
                }

            },
            resetPassword: {
                title: 'New password',
                subtitle: 'Please choose a new password',
                form: {
                    password: 'password',
                    passwordConfirmation: 'confirmation',
                    submit: {
                        default: 'Confirm this new password',
                        loading: 'Confirmation...',
                    }
                },
                notification: {
                    passwordNoMatch: 'The password and its confirmation must match',
                    passwordUpdated: 'The new password has been successfully saved'
                }
            },
            insight: {
                name: 'Insight',
                title: 'Welcome to the Spaycial Platform!'
            },
            claim: {
                name: 'Claims'
            },
            customer: {
                name: 'Members'
            },
            shoppingHub: {
                name: 'Programs',
                subtitle: 'Your Spaycial programme is just waiting for you!',
            },
            storeReferential: {
                name: 'Store repository'
            },
        }
    },
    fr: {
        resources: {
            login: {
                title: 'Bienvenue',
                subtitle: 'On a hâte de vous revoir !',
                baseline: 'Chaque jour est Spaycial !',
                form: {
                    email: 'email',
                    password: 'mot de passe',
                    passwordConfirmation: 'confirmation',
                    submit: {
                        default: 'Me connecter',
                        loading: 'Connexion...',
                    }
                },
                forgottenPassword: 'Vous avez oublié votre mot de passe ?',
                notification: {
                    blockedAccount: 'Votre compte est désactivé, veuillez faire une nouvelle demande de mot de passe',
                }
            },
            requestPassword: {
                title: 'Mot de passe oublié ?',
                subtitle: 'Veuillez saisir votre adresse mail',
                form: {
                    email: 'email',
                    submit: {
                        default: 'Envoyer un mail de réinitialisation',
                        loading: 'Envoi...',
                    }
                },
                notification: {
                    resetRequestSent: 'Votre demande a été prise en compte, veuillez consulter votre boîte mail'
                }
            },
            resetPassword: {
                title: 'Nouveau mot de passe',
                subtitle: 'Veuillez choisir un nouveau mot de passe',
                form: {
                    password: 'mot de passe',
                    passwordConfirmation: 'confirmation',
                    submit: {
                        default: 'Valider ce nouveau mot de passe',
                        loading: 'Validation...',
                    }
                },
                notification: {
                    passwordNoMatch: 'Le mot de passe et sa confirmation doivent correspondre',
                    passwordUpdated: 'Le nouveau mot de passe a été sauvegardé avec succès'
                }
            },
            insight: {
                name: 'Analyse',
                title: 'Bienvenue sur la plateforme Spaycial !'
            },
            claim: {
                name: 'Réclamations'
            },
            customer: {
                name: 'Membres'
            },
            shoppingHub: {
                name: 'Programmes',
                subtitle: 'Votre programme Spaycial n\'attend plus que vous !',
            },
            storeReferential: {
                name: 'Référentiel boutiques'
            }
        }
    },
};

const messages = {
    fr: { ...frenchMessages, ...domainMessages.fr },
    en: { ...englishMessages, ...domainMessages.en },
};
const i18nProvider = polyglotI18nProvider(
    locale => messages[locale] ? messages[locale] : messages.en,
    resolveBrowserLocale()
);

export default i18nProvider;
