import * as React from 'react';
import { Show, SimpleShowLayout, TextField, ReferenceField } from 'react-admin';

import { ShowActions } from '../../common';

const StoreShow = (props) => (
    <Show actions={<ShowActions />} {...props}>
        <SimpleShowLayout>
            <TextField source='id' />
            <TextField source='name' />
            <TextField source='shoppingHubId' label='shoppingHub ID' />
            <ReferenceField source='shoppingHubId' reference='shoppingHub' label='ShoppingHub' link='show'>
                <TextField source='name' />
            </ReferenceField>
            <TextField source='createdAt' />
        </SimpleShowLayout>
    </Show>
);

export default StoreShow;
