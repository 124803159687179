import * as React from 'react';
import { Create, SimpleForm, TextInput, DateInput } from 'react-admin';

const CustomerCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source='firstName' />
            <TextInput source='lastName' />
            <TextInput source='shoppingHubId' />
            <DateInput label='Publication date' source='createdAt' defaultValue={new Date()} />
            <DateInput label='Publication date' source='updatedAt' defaultValue={new Date()} />
        </SimpleForm>
    </Create>
);
export default CustomerCreate;
