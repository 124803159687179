import { Typography, Box } from '@material-ui/core';
import T from 'prop-types';
import React, { useMemo, useState } from 'react';
import { SaveButton, SelectInput } from 'react-admin';
import { useQuery } from 'react-query';
import { API, ApiBackOffice } from '../../../../../../utils/API';
import { ROUTES } from '../../../../../../utils/constants';
import { checkUserPermission, FUNCTIONALITIES } from '../../../../../../utils/permissionChecker';
import CreateTransactionButton from '../CreateTransactionButton';
import EditMode from './ClaimDetailsSubComponents/EditMode';
import RejectionMode from './ClaimDetailsSubComponents/RejectionMode';
import UserConfirmationPopUp from './ClaimDetailsSubComponents/UserConfirmationPopUp';

export const ClaimDetails = (props) => {
    const { formProps, data, customProps } = props;
    // data is used to get the shoppingHubId if it exists and formProps to access the method handleSubmitWithRedirect and saving
    const [open, setOpen] = useState(false);
    const [showRejectionReasons, setShowRejectionReasons] = useState(false);
    const [selectedRejectionReason, setSelectedRejectionReason] = useState({});

    const { transactionDate } = formProps.record;
    const {
        registrationDate: customerRegistrationDate,
        transactionsEligibleFromDate: customerTransactionsEligibleFromDate,
    } = data;

    const readOnlyPermission = useMemo(
        () => !checkUserPermission(FUNCTIONALITIES.SUPPORT_LVL_1, ['WRITE', 'DELETE']),
        []
    );

    const { data: shoppingHubData, isLoading: shoppingHubLoading } = useQuery(
        ['getShoppingHubDetails', props.data.shoppingHubId],
        async () => {
            const response = await ApiBackOffice.get(ROUTES.SHOPPING_HUB_DETAILS(props.data.shoppingHubId));
            return response.data;
        }
    );

    const { data: rejectionReason } = useQuery('getRejectedReasons', async () => {
        const response = await API.get(ROUTES.REJECTION_REASON_LIST);
        const customerRegistrationOrEligibleFromDate = new Date(
            customerTransactionsEligibleFromDate || customerRegistrationDate
        );
        const transactionDateObj = new Date(transactionDate);
        const valuesToRemove = ['Automatically rejected'];

        if (transactionDateObj.getTime() > customerRegistrationOrEligibleFromDate.getTime()) {
            valuesToRemove.push('Before registration date');
        }

        return response.data.filter(({ reason }) => !valuesToRemove.includes(reason));
    });

    const handleSelectedRejectionReason = (e) => {
        setSelectedRejectionReason(rejectionReason.find((reasonObj) => reasonObj.id === e.target.value));
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {
        formProps.handleSubmitWithRedirect();
        setOpen(false);
    };

    return (
        <>
            {!readOnlyPermission && (
                <SaveButton
                    fullWidth
                    style={{ marginBottom: 16 }}
                    saving={formProps.saving}
                    submitOnEnter={false}
                    handleSubmitWithRedirect={() =>
                        showRejectionReasons ? setOpen(true) : formProps.handleSubmitWithRedirect()
                    }
                />
            )}
            <UserConfirmationPopUp
                handleClose={handleClose}
                handleSubmit={handleSubmit}
                selectedRejectionReason={selectedRejectionReason}
                open={open}
            />
            <Box p='1em' border={1} borderRadius={10} borderColor='#D1D3D4'>
                <Typography variant='h6' align='center' gutterBottom>
                    Claim Details
                </Typography>

                <Box color='green' display='flex' flexDirection='column'>
                    <SelectInput
                        fullWidth
                        variant='outlined'
                        color='primary'
                        source='claimStatus'
                        disabled={readOnlyPermission}
                        choices={[
                            { id: 'rejected', name: 'rejected' },
                            { id: 'validated', name: 'validated' },
                            { id: 'pending', name: 'pending' },
                        ]}
                        onChange={(e) => {
                            setShowRejectionReasons(e.target.value === 'rejected');
                        }}
                    />
                    {showRejectionReasons ? (
                        <RejectionMode
                            rejectionReason={rejectionReason}
                            handleSelectedRejectionReason={handleSelectedRejectionReason}
                        />
                    ) : (
                        <EditMode
                            data={data}
                            readOnly={readOnlyPermission}
                            attachmentUrl={formProps.record.attachmentUrl}
                        />
                    )}
                    {!readOnlyPermission && !shoppingHubLoading && (
                        <CreateTransactionButton record={customProps.record} currency={shoppingHubData.currency} />
                    )}
                </Box>
            </Box>
        </>
    );
};

/* eslint-disable */
// object type is forbidden to use.
// shape type with description if internal fields required instead
ClaimDetails.propTypes = {
    formProps: T.object.isRequired,
    data: T.object.isRequired,
    customProps: T.shape({
        record: T.object.isRequired,
    }).isRequired,
};
/* eslint-enable */
