import { makeStyles } from '@material-ui/core/styles';
import { EditableDatagrid } from '@react-admin/ra-editable-datagrid';
import { useSelectedColumns } from '@react-admin/ra-preferences';
import { List, TextField, ReferenceField, DateField } from 'react-admin';
import { ClipboardListField } from 'react-admin-clipboard-list-field';
import COLUMNS from '../../../../../../utils/enums';
import { CustomPagination, CustomEditTransactionFormRow } from '../../../../../common';
import ClaimEditTransactionListActions from './CustomerTransactionsSubComponents/ClaimEditTransactionListActions';
import { CustomerTransactionsFilter } from './CustomerTransactionsSubComponents/CustomerTransactionsFilter';

const listStyles = makeStyles({
    root: {
        overflow: 'scroll',
        paddingTop: '15px',
    },
});

const createRows = (value) => [value];
const customerTransactionsColumns = {
    id: <ClipboardListField source='id' label='Transaction ID' iconPosition='right' createRows={createRows} />,
    label: <TextField source='label' label='Label' />,
    store: (
        <ReferenceField source='storeId' reference='store' label='Store'>
            <TextField source='name' />
        </ReferenceField>
    ),
    amount: <TextField source='amount' label='Amount' />,
    transactionDate: <DateField source='transactionDate' label='Transac.Date' />,
    createdAt: <DateField source='createdAt' label='Creation.Date' />,
    status: <TextField source='storeAssociationOrigin' label='Association' />,
};
export const CustomerTransactions = (props) => {
    // customProps are retrieved from the parent component to override parent props with new related ressource and basePath
    // like below const customProps = { ...props, resource: 'transaction', basePath: '/transaction' };
    // add childComponent to make sure to use it inside a component
    const { filter = {}, customProps, childComponent, shoppingHubId } = props;

    const displayedColumns = useSelectedColumns({
        preferences: COLUMNS.customerTransactions,
        columns: customerTransactionsColumns,
        omit: ['nb_views'],
    });

    const listClasses = listStyles(props);

    return (
        <List
            key='customer-transactions'
            {...props}
            resource={childComponent ? customProps.resource : props.resource}
            basePath={childComponent ? customProps.basePath : props.basePath}
            hasCreate={false}
            hasShow={false}
            perPage={16}
            sort={{ field: 'createdAt', order: 'DESC' }}
            pagination={<CustomPagination />}
            filters={<CustomerTransactionsFilter />}
            filter={filter}
            actions={<ClaimEditTransactionListActions columns={customerTransactionsColumns} />}
            bulkActionButtons={false}
            classes={listClasses}
        >
            <EditableDatagrid
                resource='transaction'
                size='medium'
                noDelete
                undoable
                editForm={
                    // TODO add more resilience in case of unexpected behaviour
                    <CustomEditTransactionFormRow shoppingHubId={shoppingHubId} />
                }
            >
                {displayedColumns}
            </EditableDatagrid>
        </List>
    );
};
