import { Box, Typography } from '@material-ui/core';
import T from 'prop-types';
import React from 'react';
import RewardList from '../../../Reward/RewardList/RewardList';

export const CustomerElementRewardList = ({ customerId, rewardListCustomProps }) => (
    <Box p='1em' mr='1em' mb='1em' mt='1em' border={1} borderRadius={10} borderColor='#D1D3D4'>
        <Typography variant='h6' gutterBottom>
            Customer rewards
        </Typography>
        <RewardList
            style={{ width: '100%' }}
            customProps={rewardListCustomProps}
            childComponent
            filter={{ customerId }}
        />
    </Box>
);

CustomerElementRewardList.propTypes = {
    customerId: T.string.isRequired,
};
