import styled from 'styled-components';

export const ImageFieldWrapper = styled.div`
    display: flex;
    img {
        max-width: 125px;
        height: 75px;
        margin: auto;
    }
`;
