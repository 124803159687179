import { Typography, Box, Grid } from '@material-ui/core';
import React, { useEffect, useState, useMemo } from 'react';
import { FormWithRedirect } from 'react-admin';
import { useQuery } from 'react-query';
import { API } from '../../../../../utils/API';
import { ROUTES } from '../../../../../utils/constants';
import { ClaimDetails } from './ClaimDetails';
import { CustomerDetails } from './CustomerDetails';
import { CustomerTransactions } from './CustomerTransactions';
import { FUNCTIONALITIES, checkUserPermission } from '../../../../../utils/permissionChecker'

const ClaimEditForm = (props) => {
    const { customerId } = props.record;
    const [error, setError] = useState(null);
    const customProps = { ...props, resource: 'transaction', basePath: ROUTES.TRANSACTION_LIST };

    const {
        data: customerData,
        isLoading: isLoadingCustomer,
        isError: isErrorCustomer,
        error: customerError,
    } = useQuery('getCustomerInfos', async () => {
        const response = await API.get(ROUTES.CUSTOMER_DETAILS(customerId));
        return response.data;
    });

    const shoppingHubId = customerData?.shoppingHubId;

    useEffect(() => {
        if (isErrorCustomer) {
            setError(customerError.message)
        }
    }, [isErrorCustomer, customerError]);

    if (isLoadingCustomer) {
        return <span>Loading...</span>;
    }

    if (error) {
        return <span>Error: {error}</span>;
    }

    return (
        <FormWithRedirect
            {...props}
            render={(formProps) => (
                <form>
                    <Box p='1em'>
                        {customerId && shoppingHubId && <CustomerDetails
                            {...{
                                shoppingHubId,
                                customerId,
                                setError,
                                registrationDate: customerData?.registrationDate,
                            }}
                            />
                        }

                        <Grid container>
                            <Grid xs={9}>
                                <Box p='1em' mr='1em' border={1} borderRadius={10} borderColor='#D1D3D4'>
                                    <Typography variant='h6' gutterBottom>
                                        Customer transactions
                                    </Typography>
                                    <CustomerTransactions
                                        customProps={customProps}
                                        childComponent
                                        shoppingHubId={customerData?.shoppingHubId}
                                        filter={{ customerId: customerId }}
                                    />
                                </Box>
                            </Grid>
                            <Grid xs={3} direction='column' alignItems='center' justify='flex-start'>
                                <ClaimDetails formProps={formProps} customProps={customProps} data={customerData} />
                            </Grid>
                        </Grid>
                    </Box>
                </form>
            )}
        />
    );
};

export default ClaimEditForm;
