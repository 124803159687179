const COLUMNS = {
    customerTransactions: 'customerTransactionsColumns',
    reward: 'rewardColumns',
    transaction: 'transactionColumns',
    customer: 'customerColumns',
    customerShowTransactions: 'customerShowTransactions',
    customerShowTransactionsEditable: 'customerShowTransactionsEditable',
    claim: 'claimColumns',
};

export default COLUMNS;
