import { Box } from '@material-ui/core';
import { AccordionForm, AccordionFormPanel } from '@react-admin/ra-form-layout';
import * as React from 'react';
import { Edit, TextInput, Toolbar, SaveButton } from 'react-admin';
import { useQuery } from 'react-query';
import { API } from '../../../utils/API';
import { ROUTES } from '../../../utils/constants';
import { CustomerTransactions } from '../Claim/ClaimEdit/ClaimEditForm/CustomerTransactions';
import { EditActions } from '../../common';

const CustomerEditToolbar = (props) => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
);

const CustomerEdit = (props) => {
    const { id } = props;
    const transactionListCustomProps = { ...props, resource: 'transaction', basePath: ROUTES.TRANSACTION_LIST };

    const { data, isLoading, isError, error } = useQuery('getCustomerInfos', async () => {
        const response = await API.get(ROUTES.CUSTOMER_DETAILS(id));
        return response.data;
    });

    if (isLoading) {
        return <span>Loading...</span>;
    }

    if (isError) {
        return <span>Error: {error.message}</span>;
    }

    return (
        <Edit actions={<EditActions />} {...props} component='div'>
            <AccordionForm toolbar={<CustomerEditToolbar />}>
                <AccordionFormPanel label='Customer Infos'>
                    <Box style={{ width: '100%' }} display='flex'>
                        <Box mr='2em' display='flex' flexDirection='column'>
                            <TextInput variant='outlined' disabled source='id' />
                            <TextInput variant='outlined' disabled source='email' />
                            <TextInput variant='outlined' disabled source='externalId' />
                        </Box>
                        <Box display='flex' flexDirection='column'>
                            <TextInput variant='outlined' source='firstName' />
                            <TextInput variant='outlined' source='lastName' />
                        </Box>
                    </Box>
                </AccordionFormPanel>
                <AccordionFormPanel label='Customer Transactions'>
                    <CustomerTransactions
                        style={{ width: '100%' }}
                        customProps={transactionListCustomProps}
                        childComponent
                        shoppingHubId={data?.shoppingHubId}
                        filter={{ customerId: id }}
                    />
                </AccordionFormPanel>
            </AccordionForm>
        </Edit>
    );
};

export default CustomerEdit;
