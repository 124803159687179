import { Box, Typography } from '@material-ui/core';
import T from 'prop-types';
import React from 'react';
import TransactionList from '../../../Transaction/TransactionList';

export const CustomerElementTransactionList = ({ customerId, transactionListCustomProps }) => (
    <Box p='1em' mr='1em' mb='1em' mt='1em' border={1} borderRadius={10} borderColor='#D1D3D4'>
        <Typography variant='h6' gutterBottom>
            Customer transactions
        </Typography>
        <TransactionList
            style={{ width: '100%' }}
            customProps={transactionListCustomProps}
            childComponent
            filter={{ customerId }}
        />
    </Box>
);

CustomerElementTransactionList.propTypes = {
    customerId: T.string.isRequired,
};
