import * as React from 'react';
import { MenuItemLink, useTranslate } from 'react-admin';
import HomeIcon from "@material-ui/icons/Home";
import ListIcon from "@material-ui/icons/List";
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import PeopleIcon from "@material-ui/icons/People";
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import * as styles from './styles/CustomMenu.styles';

const CustomMenu = () => {
    const t = useTranslate();
    return (
        <styles.menuWrapper>
            <div className='MenuContainer'>
                <MenuItemLink to="/shoppingHub" primaryText={t('resources.shoppingHub.name')} leftIcon={<HomeIcon/>}/>
                <MenuItemLink to="/insight" primaryText={t('resources.insight.name')} leftIcon={<EmojiObjectsIcon/>}/>
                <MenuItemLink to="/claim" primaryText={t('resources.claim.name')} leftIcon={<QuestionAnswerIcon/>}/>
                <MenuItemLink to="/customer" primaryText={t('resources.customer.name')} leftIcon={<PeopleIcon/>}/>
                <MenuItemLink to="/storeReferential" primaryText={t('resources.storeReferential.name')} leftIcon={<ListIcon/>}/>
            </div>
        </styles.menuWrapper>
    );
};

export default CustomMenu;
