import { createMuiTheme } from '@material-ui/core/styles';
import spaycialPurple from './colors/spaycialPurple';
import { defaultTheme } from 'react-admin';

const CustomTheme = createMuiTheme({
    ...defaultTheme,
    palette: {
        primary: spaycialPurple,
        secondary: spaycialPurple,
    },
    typography: {
        // Use the system font instead of the default Roboto font.
        fontFamily: ['Nunito', 'sans-serif'].join(','),
    }
});

export default CustomTheme;
