import * as React from "react";
import { Card, CardContent, CardHeader, Box } from '@material-ui/core';
import {useEffect} from "react";
import {useTranslate} from "react-admin";

const initializeViz = () => {
    const placeholderDiv = document.getElementById('tableauViz');
    const url = "https://analytics.transactionconnect.com/views/Memberactivity-U/Overview?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link";
    const options = {
        width: '1500px',
        height: '1200px',
        hideTabs: true,
        hideToolbar: true,
    };
    const viz = new tableau.Viz(placeholderDiv, url, options);
}


const Dashboard = () => {
    useEffect(() => {
        initializeViz()
    }, []);
    const t = useTranslate();

    return (
        <Box>
            <Card variant="outlined">
                <div id="tableauViz" style={{ margin: '20px' }} />
            </Card>
        </Box>
    );
};
export default Dashboard;
