import Rollbar from 'rollbar';

import { logAnalytics } from '../utils/logger';

const { log } = logAnalytics();

export const initRollbar = () => {
    log(`Init Rollbar ${process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN}`);
    // eslint-disable-next-line
    new Rollbar({
        accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
        handleUncaughtExceptions: true,
        handleUnhandledRejections: true,
        enabled: process.env.NODE_ENV !== 'development',
        scrubTelemetryInputs: true,
        payload: {
            environment:
                process.env.REACT_APP_TC_HOST === 'https://api-test.transactionconnect.com'
                    ? 'staging'
                    : 'developpement', // Set dynamic environment
            autoInstrument: true,
            client: {
                javascript: {
                    source_map_enabled: true,
                    guess_uncaught_frames: true,
                    code_version: '99b17b805fe319537b6723a2f6891e851aec3df1', // git SHA of the current revision
                },
            },
            server: {
                host: process.env.REACT_APP_TC_HOST,
            },
        },
    });
};
