import { TableRow, TableCell } from '@material-ui/core';
import { SaveRowButton } from '@react-admin/ra-editable-datagrid';
import { required, AutocompleteInput, ReferenceInput } from 'react-admin';
import { Form } from 'react-final-form';

import * as styles from './CustomEditTransactionFormRow.styles';
import jwtDecode from "jwt-decode";

export const CustomEditTransactionFormRow = (props) => {
    const {
        shoppingHubId,
        record,
        id,
        className,
        quitEditMode,
        selectable,
        basePath,
        resource,
        save,
        saving,
        selected,
        undoable,
        ...rest
    } = props;
    const token = sessionStorage.getItem('accessToken');
    const backUserId = token ? jwtDecode(token).backUserId : null;
    return (
        <Form initialValues={{ ...record, storeAssociationOrigin: 'MAN', storeAssociationDate: new Date(), storeAssociationAuthor: backUserId }} onSubmit={save} {...rest}>
            {({ handleSubmit, invalid, dirty }) => (
                <TableRow className={className} style={{ backgroundColor: '#F3F3F3' }} key={id}>
                    <TableCell />
                    <TableCell />
                    <TableCell>
                        <styles.HubSelectorWrapper>
                            <ReferenceInput
                                fullWidth
                                variant='outlined'
                                style={{ width: 200 }}
                                label='Store Name'
                                validate={required()}
                                source='storeId'
                                reference='store'
                                perPage={false}
                                filter={{ shoppingHubId: shoppingHubId }}
                                sort={{ field: 'name', order: 'ASC' }}
                            >
                                <AutocompleteInput
                                    resettable
                                    options={{
                                        color: 'secondary',
                                        suggestionsContainerProps: {
                                            disablePortal: true,
                                            className: 'ra-ref-input',
                                        },
                                    }}
                                />
                            </ReferenceInput>
                        </styles.HubSelectorWrapper>
                    </TableCell>
                    <TableCell>
                        <SaveRowButton
                            dirty={dirty}
                            handleSubmit={handleSubmit}
                            invalid={invalid}
                            quitEditMode={quitEditMode}
                            saving={saving}
                            undoable={undoable}
                        />
                    </TableCell>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                </TableRow>
            )}
        </Form>
    );
};
