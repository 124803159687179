import { SelectColumnsButton } from '@react-admin/ra-preferences';
import { cloneElement } from 'react';
import { useListContext, sanitizeListRestProps, TopToolbar } from 'react-admin';
import COLUMNS from '../../../../../../../utils/enums';

const ClaimEditTransactionListActions = (props) => {
    const { className, exporter, filters, maxResults, columns, ...rest } = props;
    const { resource, displayedFilters, filterValues, showFilter } = useListContext();
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters &&
                cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    context: 'button',
                })}
            <SelectColumnsButton label='Columns' preference={COLUMNS.customerTransactions} columns={columns} />
        </TopToolbar>
    );
};

export default ClaimEditTransactionListActions;
