import { Box } from '@material-ui/core';
import T from 'prop-types';
import React from 'react';

export const BoxElementDisplay = ({ children }) => (
    <Box display='flex' flexDirection='column'>
        {children}
    </Box>
);

BoxElementDisplay.propTypes = {
    children: T.element.isRequired,
};
