import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import T from 'prop-types';
import React from 'react';

const UserConfirmationPopUp = ({ open, handleClose, handleSubmit, selectedRejectionReason }) => {
    const { reason: rejectionReason } = selectedRejectionReason;
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
        >
            <DialogTitle id='alert-dialog-title'>Are you sure?</DialogTitle>
            <DialogContent>
                <DialogContentText id='alert-dialog-description'>
                    Do you want to proceed with the claim rejection for the following reason {rejectionReason}?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color='primary'>
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color='primary' autoFocus>
                    Yes, I Agree
                </Button>
            </DialogActions>
        </Dialog>
    );
};

UserConfirmationPopUp.defaultProps = {
    open: false,
};

UserConfirmationPopUp.propTypes = {
    open: T.bool,
    handleClose: T.func.isRequired,
    handleSubmit: T.func.isRequired,
    selectedRejectionReason: T.shape({
        id: T.string.isRequired,
        reason: T.string.isRequired,
    }).isRequired,
};

export default UserConfirmationPopUp;
