import T from 'prop-types';
import React from 'react';
import { required, SelectInput } from 'react-admin';

const RejectionMode = ({ rejectionReason, handleSelectedRejectionReason }) => (
    <SelectInput
        fullWidth
        label='Rejection Reason'
        variant='outlined'
        color='primary'
        source='rejectionReasonId'
        choices={rejectionReason}
        optionText='reason'
        onChange={handleSelectedRejectionReason}
        validate={[required()]}
    />
);

RejectionMode.propTypes = {
    rejectionReason: T.arrayOf(T.shape({ id: T.string.isRequired, name: T.string.isRequired })).isRequired,
    handleSelectedRejectionReason: T.func.isRequired,
};

export default RejectionMode;
