import * as React from 'react';
import { Show, SimpleShowLayout, TextField, ReferenceField } from 'react-admin';

import { ShowActions } from '../../common';

const TransactionShow = (props) => (
    <Show actions={<ShowActions />} {...props}>
        <SimpleShowLayout>
            <TextField source='id' />
            <TextField source='label' />
            <ReferenceField source='storeId' reference='store' label='store'>
                <TextField source='name' />
            </ReferenceField>
            <TextField source='transactionDate' />
            <TextField source='amount' />
            <TextField source='customerId' />
            <TextField source='createdAt' />
        </SimpleShowLayout>
    </Show>
);

export default TransactionShow;
