import T from 'prop-types';
import React from 'react';
import { customerShowStyle } from '../../../../../utils/style';
import { BoxElementDisplay, TypoElement } from '../../../../common';

export const CustomerElement = ({ title, element }) => (
    <BoxElementDisplay>
        <TypoElement content={title} {...customerShowStyle[0]} />
        <TypoElement content={element} {...customerShowStyle[1]} />
    </BoxElementDisplay>
);

CustomerElement.propTypes = {
    title: T.string.isRequired,
    element: T.string.isRequired,
};
