import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { useSelectedColumns } from '@react-admin/ra-preferences';
import React, { useState } from 'react';
import { List, Datagrid, TextField, ReferenceField, DateField } from 'react-admin';
import COLUMNS from '../../../utils/enums';
import { CustomPagination, ListActions } from '../../common';
import ClaimFilter from './ClaimFilter';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
};
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const claimListColumns = {
    id: <TextField source='customerId' label='Customer ID' />,
    name: (
        <ReferenceField source='storeId' reference='store' label='Store' link='show'>
            <TextField source='name' />
        </ReferenceField>
    ),
    amount: <TextField source='amount' label='Amount' />,
    transactionDate: <DateField source='transactionDate' label='Transac.Date' />,
    createdAt: <DateField source='createdAt' label='Created At' />,
    updatedAt: <DateField source='updatedAt' label='Updated At' />,
    claimStatus: <TextField source='claimStatus' label='Status' />,
};

const ClaimList = (props) => {
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const displayedColumns = useSelectedColumns({
        preferences: COLUMNS.claim,
        columns: claimListColumns,
        omit: ['nb_views'],
    });
    return (
        <div>
            <Tabs value={value} onChange={handleChange} variant='fullWidth' aria-label='simple tabs example'>
                <Tab label='Pending' {...a11yProps(0)} />
                <Tab label='All' {...a11yProps(1)} />
            </Tabs>
            <TabPanel value={value} index={0}>
                <List
                    {...props}
                    sort={{ field: 'createdAt', order: 'DESC' }}
                    perPage={16}
                    pagination={<CustomPagination />}
                    filters={<ClaimFilter />}
                    filter={{ claimStatus: 'pending' }}
                    actions={<ListActions columns={claimListColumns} preference={COLUMNS.claim} />}
                    bulkActionButtons={false}
                    title='Claims'
                >
                    <Datagrid rowClick='edit'>{displayedColumns}</Datagrid>
                </List>
            </TabPanel>

            <TabPanel value={value} index={1}>
                <List
                    {...props}
                    sort={{ field: 'createdAt', order: 'DESC' }}
                    perPage={16}
                    pagination={<CustomPagination />}
                    filters={<ClaimFilter />}
                    actions={<ListActions columns={claimListColumns} preference={COLUMNS.claim} />}
                    bulkActionButtons={false}
                    title='Claims'
                >
                    <Datagrid rowClick='edit'>{displayedColumns}</Datagrid>
                </List>
            </TabPanel>
        </div>
    );
};

export default ClaimList;
