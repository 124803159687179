import { useSelectedColumns } from '@react-admin/ra-preferences';
import { List, Datagrid, TextField, ReferenceField, DateField } from 'react-admin';
import COLUMNS from '../../../utils/enums';
import { CustomPagination, ListActions } from '../../common';
import TransactionFilter from './TransactionFilter';

const transactionListColumns = {
    id: <TextField source='id' label='transaction ID' />,
    transactionLabel: <TextField source='cleanLabel' label='Transaction Label' />,
    storeName: (
        <ReferenceField source='storeId' reference='store' label='Store Name'>
            <TextField source='name' />
        </ReferenceField>
    ),
    transactionDate: <DateField source='transactionDate' label='Transaction Date' />,
    amount: <TextField source='amount' label='Amount' />,
    currency: <TextField source='currency' label='Currency' />,
    storeAssociationOrigin: <TextField source='storeAssociationOrigin' label='Association Status' />,
    storeAssociationDate: <DateField source='storeAssociationDate' label='Association Date' />,
    createdAt: <DateField source='createdAt' label='Created At' />,
};
const TransactionList = (props) => {
    const { filter = {}, customProps, childComponent } = props;
    const displayedColumns = useSelectedColumns({
        preferences: COLUMNS.transaction,
        columns: transactionListColumns,
        omit: ['nb_views'],
    });
    return (
        <List
            key='transaction-list'
            {...props}
            resource={childComponent ? customProps.resource : props.resource}
            basePath={childComponent ? customProps.basePath : props.basePath}
            hasCreate={false}
            perPage={16}
            pagination={<CustomPagination />}
            sort={{ field: 'createdAt', order: 'DESC' }}
            filters={<TransactionFilter />}
            filter={filter}
            actions={<ListActions columns={transactionListColumns} preference={COLUMNS.reward} />}
            bulkActionButtons={false}
        >
            <Datagrid size='medium'>{displayedColumns}</Datagrid>
        </List>
    );
};

export default TransactionList;
