import styled from 'styled-components';

export const loginWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    background-color: #FAFAFA;
    height: 100vh;
    
    .page__title__container {
        height: 100vh;
        width: 40%;
        border-radius: 0 80px 80px 0;
        background-color: #633BEB;
        position: relative;

        h1 {
            font-style: normal;
            font-weight: 800;
            font-size: 80px;
            color: white;
            position: absolute;
            bottom: 0;
            right: 0;
            padding: 20px;
            line-height: 109px;
        }

        .page__logo {
            img {
                position: absolute;
                width: 172px;
                height: 64px;
                left: 42px;
                top: 42px;
            }
        }
    }
    
    @media (max-width: 1023px) {
        .page__title__container {
            h1 {
                font-size: 50px;
                line-height: 75px;
                max-width: 200px;   
            }
        } 
    }
    
    @media (max-width: 739px) {
        .page__title__container {
            width: 10%;
            
            h1 { display: none; }
        } 
    }
    
    @media (max-width: 480px) { 
        .page__title__container {
            display: none;
        }
    }
`;

export const formContainer = styled.div`
    width: 55%;
    display: flex;
    flex-direction: column;
    text-align: center;
    
    @media(max-width: 739px) {
        padding: 0 20px;
        width: 85%
    }

    .form__title {
        text-align: center;
        margin-top: 110px;
        h1 {
            margin-bottom: 5px;
            font-style: normal;
            font-family: Nunito, sans-serif;
            font-weight: 800;
            font-size: 40px;
            line-height: 55px;
            color: #633BEB;
        }
        span {
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 22px;
            color: #000000;
        }
    }

    .form__fields {
        text-align: center;
        margin-top: 20px;

        .MuiTextField-root {
            margin-top: 10px;
            width: 360px;
            height: 62px;
            border-radius: 10px;
          
            input {
              background-color: #FFFFFF;
              box-shadow: 0 0 15px rgba(0, 0, 0, 0.07);
            }
        }
    }

    .form__button {
        margin-top: 30px;
        width: 360px;
        height: 62px;
        border-radius: 10px;
        border: 1px solid #633BEB;
        background-color: white;
        font-family: Nunito, sans-serif;
        font-weight: 700;
        font-size: 16px;
        color:#633BEB;
        text-transform: none;
    }

    .form__button:disabled{
        color:#7a58ee;
        background-color:#d0c4f9;
    }
    .form__button:hover{
      background-color:#633BEB;
      color:white;
    }
    .form__link {
      color:#633BEB;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
      text-decoration: none;
    }
    .form__link:hover {
      text-decoration: underline;
    }
    .form__link__container{
      margin-top: 10px;
    }
    
    @media (max-width: 480px) { 
        margin: 0 auto;
        
        .form__fields { 
            .MuiTextField-root {
                width: 100%;
            }
        }
        
        .form__button {
            width: 100%;
        }
    }
`;
