import * as React from 'react';
import { DateInput, Edit, required, SimpleForm, TextInput, Toolbar, SaveButton } from 'react-admin';

const StoreEditToolbar = (props) => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
);

const StoreEdit = (props) => (
    <Edit {...props}>
        <SimpleForm toolbar={<StoreEditToolbar />}>
            <TextInput disabled source='id' />
            <TextInput source='name' validate={[required()]} />
            <TextInput source='shoppingHubId' label='shoppingHub ID' validate={[required()]} />
            <DateInput source='createdAt' validate={[required()]} />
        </SimpleForm>
    </Edit>
);

export default StoreEdit;
