import Button from '@material-ui/core/Button';
import React, { useState } from 'react';
import { useLogin, required, Loading, useNotify, Notification, useTranslate } from 'react-admin';
import { Form } from 'react-final-form';
import LoginTextField from './LoginTextField';
import * as styles from './styles/Login.styles';
import {Link} from "react-router-dom";

const LoginPage = () => {
    const [loading, setLoading] = useState(false);
    const login = useLogin();
    const notify = useNotify();
    const t = useTranslate();

    const onSubmit = (data) => {
        setLoading(true);
        login(data)
            .then(() => setLoading(false))
            .catch((err) => {
                setLoading(false);
                if(err.message === 'Failed to fetch'){
                    notify(t('ra.notification.http_error'), 'error');
                } else if(err.code) {
                    const signInDefaultError = t('ra.auth.sign_in_error')
                    const codeTranslation = `resources.login.notification.${err.code}`
                    notify(t(codeTranslation, { _: signInDefaultError}), 'error');
                } else {
                    notify(t('ra.auth.sign_in_error'), 'error');
                }
            });
    };

    return (
        <styles.loginWrapper>
            <div className='page__title__container'>
                <h1>{t('resources.login.baseline')}</h1>
            </div>
            <styles.formContainer>
                <div className='form__title'>
                    <h1>{t('resources.login.title')}</h1>
                    <span>{t('resources.login.subtitle')}</span>
                </div>
                <Form
                    onSubmit={onSubmit}
                    render={({ handleSubmit, form }) => (
                        <form onSubmit={(data) => handleSubmit(data)} className='form-container'>
                            <LoginTextField
                                source='email'
                                type='email'
                                name='email'
                                label={t('resources.login.form.email')}
                                validate={[required()]}
                            />
                            <LoginTextField
                                source='password'
                                type='password'
                                name='password'
                                label={t('resources.login.form.password')}
                                validate={[required()]}
                            />
                            <Button
                                variant='contained'
                                color='primary'
                                disableElevation
                                type='submit'
                                className='form__button'
                                disabled={loading}
                            >
                                {loading && (
                                    <i
                                        className="fa fa-refresh fa-spin"
                                        style={{ marginRight: "5px" }}
                                    />
                                )}
                                {loading && <span>{t('resources.login.form.submit.loading')}</span>}
                                {!loading && <span>{t('resources.login.form.submit.default')}</span>}
                            </Button>
                            <div className='form__link__container'>
                                <Link to='/requestPasswordReset' className='form__link'>
                                    {t('resources.login.forgottenPassword')}
                                </Link>
                            </div>
                        </form>
                    )}
                />
                <Notification />
            </styles.formContainer>
        </styles.loginWrapper>
    );
};

export default LoginPage;
