import styled from 'styled-components';

export const menuWrapper = styled.div`

  .MenuContainer {
    margin-top: 30px;
    
    a {
      padding-top: 15px;
    }
  }
  
`;
