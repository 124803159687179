import * as React from 'react';
import { DateInput, Edit, required, SimpleForm, TextInput, SelectInput, Toolbar, SaveButton } from 'react-admin';

const TransactionEditToolbar = (props) => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
);
const TransactionEdit = (props) => (
    <Edit {...props}>
        <SimpleForm toolbar={<TransactionEditToolbar />}>
            <TextInput disabled source='id' />
            <DateInput source='transactionDate' defaultValue={new Date()} />
            <TextInput source='amount' validate={[required()]} />
            <TextInput source='currency' validate={[required()]} />
            <TextInput source='label' validate={[required()]} />
            <TextInput source='provider' validate={[required()]} />
            <TextInput source='customerId' validate={[required()]} />
            <TextInput source='storeId' validate={[required()]} />
            <DateInput source='createdAt' validate={[required()]} />
            <SelectInput
                validate={[required()]}
                source='storeAssociationOrigin'
                choices={[{ id: 'bo-analysis', name: 'BO Analysis' }]}
            />
            <DateInput source='storeAssociationDate' validate={[required()]} />
        </SimpleForm>
    </Edit>
);

export default TransactionEdit;
