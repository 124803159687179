import { Filter, TextInput, SelectInput, DateInput } from 'react-admin';

const ClaimFilter = (props) => (
    <Filter {...props}>
        <DateInput variant='outlined' alwaysOn source='transactionDate' />
        <TextInput variant='outlined' alwaysOn source='amount' />
        <TextInput variant='outlined' alwaysOn source='storeId' label='Store ID' />
        <TextInput variant='outlined' alwaysOn source='customerId' label='Customer ID' />

        <SelectInput
            variant='outlined'
            alwaysOn
            source='claimStatus'
            choices={[
                { id: 'validated', name: 'validated' },
                { id: 'rejected', name: 'rejected' },
                { id: 'pending', name: 'pending' },
            ]}
        />
    </Filter>
);

export default ClaimFilter;
