import { Typography } from '@material-ui/core';
import T from 'prop-types';
import React from 'react';

export const TypoElement = ({ content, variant, color, style }) => (
    <Typography variant={variant} color={color} style={style}>
        {content}
    </Typography>
);

TypoElement.defaultProps = {
    color: '',
};

TypoElement.propTypes = {
    content: T.string.isRequired,
    variant: T.string.isRequired,
    color: T.string,
};
