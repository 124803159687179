import { API } from './Api';
import { ApiBackOffice } from './ApiBackOffice';

export const startSession = (token, userInfo) => {
    sessionStorage.setItem('accessToken', token);
    sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
    API.attachToken(token);
    ApiBackOffice.attachToken(token);
};

export const stopSession = () => {
    sessionStorage.removeItem('accessToken');
    sessionStorage.removeItem('userInfo');
    API.detachToken();
    ApiBackOffice.detachToken();
};

export { API, ApiBackOffice };
