Object.defineProperty(exports, "__esModule", {
    value: true
});
const spaycialPurple = {
    '50': '#ece7fd',
    '100': '#d0c4f9',
    '200': '#b19df5',
    '300': '#9276f1',
    '400': '#7a58ee',
    '500': '#633beb',
    '600': '#5b35e9',
    '700': '#512de5',
    '800': '#4726e2',
    '900': '#3519dd',
    'A100': '#ffffff',
    'A200': '#e0dcff',
    'A400': '#633beb',
    'A700': '#9d90ff',
};
const _default = spaycialPurple;
exports.default = _default;
