import * as React from 'react';
import { Create, SimpleForm, TextInput, required, ReferenceInput, SelectInput } from 'react-admin';

export const StoreReferentialCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect='list'>
            <TextInput source='name' validate={[required()]} variant='outlined' />
            <TextInput source='alias' variant='outlined' />
            <ReferenceInput source='shoppingHubId' reference='shoppingHub' label='ShoppingHub' variant='outlined'>
                <SelectInput optionText='name' variant='outlined' />
            </ReferenceInput>
            <TextInput source='externalId' label='external ID' variant='outlined' />
            <TextInput source='locality' label='Locality' variant='outlined' />
        </SimpleForm>
    </Create>
);
