import { Filter, TextInput, NumberInput, DateInput } from 'react-admin';

const RewardFilters = (props) => (
    <Filter {...props}>
        <TextInput alwaysOn variant='outlined' source='customerId' label='Customer Id' />
        <DateInput alwaysOn variant='outlined' source='createdAt' label='Date' />
        <NumberInput alwaysOn variant='outlined' source='amount' label='Amount' />
    </Filter>
);
export default RewardFilters;
