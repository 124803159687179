import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import HomeIcon from '@material-ui/icons/Home';
import PeopleIcon from '@material-ui/icons/People';
import ReceiptIcon from '@material-ui/icons/Receipt';
import StoreMallDirectoryIcon from '@material-ui/icons/StoreMallDirectory';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import { PreferencesBasedThemeProvider } from '@react-admin/ra-preferences';
import React from 'react';
import { Admin, Resource } from 'react-admin';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Route } from 'react-router-dom';
import {
    ProgramList,
    ClaimList,
    ClaimShow,
    ClaimEdit,
    TransactionCreate,
    TransactionEdit,
    TransactionShow,
    CustomerShow,
    CustomerCreate,
    CustomerEdit,
    CustomerList,
    StoreReferentialList,
    StoreReferentialCreate,
    StoreReferentialEdit,
    StoreReferentialShow,
    StoreCreate,
    StoreEdit,
    StoreShow,
} from './components';
import Dashboard from './components/pages/Insight/Dashboard';
import LoginPage from './components/pages/Login/LoginPage';
import CustomLayout from './layouts/CustomLayout';
import CustomTheme from './layouts/CustomTheme';
import authProvider from './providers/authProvider';
import i18nProvider from './providers/i18nProvider';
import { restProvider } from './providers/restProvider';
import RequestPassword from './components/pages/Login/RequestPassword';
import ResetPassword from './components/pages/Login/ResetPassword';
import './App.css';

const queryClient = new QueryClient();

const App = () => (
    <QueryClientProvider client={queryClient}>
        <PreferencesBasedThemeProvider>
            <Admin
                loginPage={LoginPage}
                layout={CustomLayout}
                authProvider={authProvider}
                dataProvider={restProvider}
                i18nProvider={i18nProvider}
                theme={CustomTheme}
                customRoutes={[
                    <Route exact path='/requestPasswordReset' component={RequestPassword} noLayout />,
                    <Route exact path='/resetPassword' component={ResetPassword} noLayout />,
                    <Route exact path='/insight' component={Dashboard} />,
                ]}
            >
                <Resource name='claim' icon={WhatshotIcon} list={ClaimList} edit={ClaimEdit} show={ClaimShow} />
                <Resource
                    name='customer'
                    icon={PeopleIcon}
                    list={CustomerList}
                    create={CustomerCreate}
                    show={CustomerShow}
                    edit={CustomerEdit}
                />
                <Resource
                    name='shoppingHub'
                    options={{ label: 'Programmes' }}
                    icon={StoreMallDirectoryIcon}
                    list={ProgramList}
                />
                {/* not displayed for the moment to display add list param */}
                <Resource name='reward' icon={AccountBalanceWalletIcon} />
                <Resource name='challenge' />
                <Resource name='bankShoppingHubAssociation' />
                <Resource
                    name='transaction'
                    options={{ label: 'Transactions' }}
                    icon={ReceiptIcon}
                    create={TransactionCreate}
                    show={TransactionShow}
                    edit={TransactionEdit}
                />
                <Resource name='store' icon={HomeIcon} create={StoreCreate} show={StoreShow} edit={StoreEdit} />
                <Resource
                    name='storeReferential'
                    options={{ label: 'Store Referential' }}
                    list={StoreReferentialList}
                    create={StoreReferentialCreate}
                    edit={StoreReferentialEdit}
                    show={StoreReferentialShow}
                />
            </Admin>
        </PreferencesBasedThemeProvider>
    </QueryClientProvider>
);

export default App;
